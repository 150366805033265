import React, {useRef, useState} from 'react';
import ReactPlayer from "react-player";
import {TVPlayer, useTVPlayerStore} from "react-tv-player";
import {useSearchParams} from "react-router-dom";
import {STREAM_SERVER} from "./Cakhia";
import {redirect} from "react-router-dom";
import {message} from "antd";

const Iptv = () => {
    const [params] = useSearchParams();
    const url = params.get("url");
    const player = useTVPlayerStore(s => s.player)
    const [playerKey, setPlayerKey] = useState(Math.random());
    return (
        <div>
            {url ? <TVPlayer key={playerKey}
                             url={url}
                             controls playing width='100%'
                             height='100vh'
                             onError={async (error, data, hlsInstance) => {
                                 console.log(error, data, hlsInstance, player)
                                 await message.warning(data?.details)
                                 setTimeout(() => {
                                     // window.location.reload()
                                 }, 300)

                             }}
                             onEnded={() => {
                                 window.location.reload()
                             }}
            /> : <span>stream not exist</span>}

        </div>
    );
};

export default Iptv;
