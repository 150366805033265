import React, {useState} from 'react';
import {TVPlayer} from "react-tv-player";
import {useSearchParams} from "react-router-dom";
import {STREAM_SERVER} from "./Cakhia";
import ReactHlsPlayer from "react-hls-player";

const FBTV = () => {
    const [params] = useSearchParams();
    const url = params.get("url");

    const [streamLink, setStreamLink] = useState(`${STREAM_SERVER}/tv?&url=${url}`)
    return (
        <div>
            {url ? <TVPlayer
                url={streamLink}
                controls playing width='100%'
                height='100vh' onError={(error, data, hlsInstance) => {
                console.log(error, data, hlsInstance)
                // window.location.reload()
            }}
                onEnded={(e) => {
                    console.log("eennddd", e)
                    window.location.reload()
                }}
            /> : <span>stream not exist</span>}
        </div>
    );
};

export default FBTV;
