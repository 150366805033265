import React, {useEffect, useState} from 'react';
import axios from "axios";
import {STREAM_SERVER} from "./Cakhia";
import {ProCard, ProList} from "@ant-design/pro-components";
import {Col, Row, Spin} from "antd";

const TVStatus = ({link, status}) => {
    // const [status, setStatus] = useState({})
    // const [loading, setLoading] = useState(false);
    // useEffect(() => {
    //     setLoading(true)
    //     axios.get(`${STREAM_SERVER}/channel/status?&url=${link}`).then((res) => {
    //         setStatus(res.data)
    //         setLoading(false)
    //     })
    // }, [link]);
    return <Spin spinning={false} tip={"Checking status"}>
        <img
            src={status?.frame || "https://image.freepik.com/free-vector/people-queue-cinema-ticket-office-cartoon_82574-12221.jpg"}
            alt="status" width={200} height={113}/>
    </Spin>
}

const Tv = ({provider}) => {
    const [channelInfos, setChannelInfos] = useState({})
    const [loading, setLoading] = useState(false);
    const getAllMatches = async () => {
        setLoading(true)
        const response = await axios.get(`${STREAM_SERVER}/iptv`);
        setChannelInfos(response.data)
        setLoading(false)
    }

    useEffect(() => {
        provider == "tv" && !loading && getAllMatches()
    }, [provider]);
    const values = Object.values(channelInfos);
    console.log(values)
    const channels = values.filter(group => group.filter(c => c.status?.alive).length)?.map(channels => {
        const group = channels[0].group

        return <ProList dataSource={channels?.filter(c => c.status?.alive && c.link && !c.link?.endsWith("mpegts"))}
                        loading={loading}
                        headerTitle={group}
                        grid={{gutter: 16, lg: 3, sm: 1, xs: 1, md: 2, xl: 3, xxl: 6}}
                        onItem={(record) => {
                            return {
                                onClick: () => {
                                    window.open(`${window.location.origin}/view-fb?url=${record.link}`, "_blank")
                                },
                            };
                        }}
                        metas={{
                            title: {
                                dataIndex: "group",
                                render: text => <span style={{paddingLeft: 8}}>{text}</span>
                            },
                            avatar: {
                                dataIndex: 'img'
                            },

                            content: {
                                dataIndex: "name",
                                render: (_, record) => {
                                    return <Row justify={"center"} style={{width: '100%'}}><Col span={24} style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center"
                                    }}>
                                        {/*<Avatar src={record.img} alt={record.name}/>*/}
                                        <h3>{record.name}</h3>
                                        <TVStatus link={record.link} status={record.status}/>
                                    </Col></Row>
                                }
                            }
                        }}/>

    });
    return (
        <>
            <ProCard ghost id={"tv-channel"}>
                {channels?.length ? channels : <Spin spinning={loading} tip={"Finding Channels"}>
                    <img width={"100%"}
                         src="https://img.freepik.com/free-vector/business-team-looking-new-people-allegory-searching-ideas-staff-woman-with-magnifier-man-with-spyglass-flat-illustration_74855-18236.jpg?w=2000&t=st=1704961039~exp=1704961639~hmac=5e0f70c7fbff3f714bbf607dd5ad0965ef34fc9f100b9010e9c9cbe1a015fb3e"
                         alt="search for channels"/>
                </Spin>}
            </ProCard>

        </>
    );
};

export default Tv;
