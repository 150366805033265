import React, {useEffect, useState} from 'react';
import {ProCard, ProList} from "@ant-design/pro-components";
import {Avatar, Col, Row, Badge, Tag, Modal, Select, Radio, Space, Checkbox, Button} from "antd";
import moment from "moment-timezone";
import MatchInfo from "./MatchInfo";
import _ from "lodash";

const CheckboxGroup = Checkbox.Group;
const Matches = ({matches, referer, isVebo}) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [selectedMatch, setSelectedMatch] = useState(null)
    const [filteredMatches, setFilteredMatches] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState({})
    const tournamentGroups = _.groupBy(matches, value => value.tournament.name);
    const ts = Object.values(tournamentGroups).map(d => d[0].tournament).filter(t => t.priority && t.priority > 0 || t.priority == undefined);
    const tournamentFilters = _.orderBy(ts, t => !isVebo ? t.name : t.priority, "desc").map(t => t.name)?.map(name => ({
        label: `${name}(${tournamentGroups[name]?.length})`,
        value: name
    }));
    const matchByStatus = _.groupBy(matches, value => value.match_status);
    const status = Object.keys(matchByStatus).map(text => ({
        label: `${text} (${matchByStatus[text]?.length})`,
        value: text
    }));
    console.log(tournamentGroups, tournamentFilters)
    useEffect(() => {
        const {status, tournaments, sorter} = filteredOptions;
        let filteredMatches = matches;
        if (status?.length > 0) {
            filteredMatches = filteredMatches.filter(t => status.includes(t.match_status))
        }
        if (tournaments?.length > 0) {
            filteredMatches = filteredMatches.filter(t => tournaments.includes(t.tournament?.name))
        }
        if (sorter) {
            const [field, direction] = sorter.split("-")
            filteredMatches = _.orderBy(filteredMatches, field, direction)
        }
        console.log(filteredOptions)
        setFilteredMatches(filteredMatches)

    }, [filteredOptions, matches]);
    return (
        <div>
            <Modal title={"Links"} open={!!selectedMatch} onCancel={() => setSelectedMatch(null)} footer={null}>
                <MatchInfo {...{...(selectedMatch || {}), referer, isVebo}}/>
            </Modal>

            <ProCard>
                <Row style={{flexDirection: 'column'}}>
                    <Space align="center" style={{marginBottom: 16, marginRight: 16}}>
                        Tournaments: <Select value={filteredOptions.tournaments}
                                             onChange={value => {
                                                 setFilteredOptions({...filteredOptions, tournaments: value})
                                             }}
                                             options={tournamentFilters} allowClear popupMatchSelectWidth={300}
                                             mode="multiple"/>
                    </Space>
                    <Space align="center" style={{marginBottom: 16, marginRight: 16}}>
                        Status: <CheckboxGroup options={status} value={filteredOptions.status}
                                               onChange={checkedValue => {
                                                   setFilteredOptions({...filteredOptions, status: checkedValue})
                                               }}/>
                    </Space>
                </Row>
            </ProCard>
            <ProList
                rowKey="id"
                headerTitle="Matches"
                dataSource={filteredMatches}
                pagination={{
                    defaultPageSize: 12,
                }}
                toolBarRender={() => {
                    return [
                        <Radio.Group defaultValue={"timestamp-asc"} onChange={val => {
                            setFilteredOptions({...filteredOptions, sorter: val.target.value})
                        }}>
                            <Radio.Button value="timestamp-desc">Latest first</Radio.Button>
                            <Radio.Button value="timestamp-asc">Oldest first</Radio.Button>
                        </Radio.Group>
                    ];
                }}
                onItem={(record) => {
                    return {
                        onClick: () => {
                            setSelectedMatch({matchId: record.id, urls: record.play_urls, linkId: record.linkId})
                        },
                    };
                }}
                grid={{gutter: 16, lg: 3, sm: 1, xs: 1, md: 1, xl: 3, xxl: 3}}
                metas={{
                    avatar: {dataIndex: ["tournament", "logo"], render: src => <Avatar src={src} shape={"circle"}/>},
                    title: {
                        render: (name, record) => {
                            return (<Tag color="magenta">{record.tournament?.name}</Tag>);
                        },
                    },
                    // subTitle: {
                    //     render: (name, record) => {
                    //         const isLive = record.match_status == "live";
                    //         if (isLive) {
                    //             return <Tag color={"#f50"} className={"live"}> <Badge color={"white"}/> LIVE</Tag>
                    //         }
                    //         return <></>
                    //     },
                    // },
                    content: {
                        render: (_, record) => {
                            const time = moment(record.timestamp).tz('Asia/Jakarta');
                            const scores = record.scores
                            const isLive = record.match_status == "live";
                            const isFinished = record.match_status=="finished";
                            const isPlay= isLive||isFinished
                            return <Row style={{width: '100%'}} align={"top"} justify={"space-between"}>
                                <Col span={11}
                                     style={{
                                         display: "flex",
                                         flexDirection: "column",
                                         alignItems: "center",
                                         justifyContent: "center", textAlign: "center"
                                     }}>
                                    <Avatar src={record.home.logo}/>
                                    <div style={{textAlign: "center"}}>{record.home.name}</div>
                                    {scores && isPlay&& < >
                                        <span style={{fontSize: 18}}> ({scores.home})</span>
                                    </>}
                                </Col>
                                <Col span={2}
                                     style={{alignItems: "center", display: 'flex', flexDirection: "column"}}>
                                    {/*{isLive && <Tag color={"red"}>Live</Tag>}*/}
                                    <div style={{
                                        fontSize: 24,
                                        marginBottom: 15,
                                        fontWeight: 600
                                    }}>{time.format('HH:mm')}</div>
                                    <div style={{fontSize: 14,marginBottom:4}}>{time.format('DD/MM ')}</div>
                                    {isLive&&<Tag color={"#f50"} className={"live"}> <Badge color={"white"}/> LIVE</Tag>}
                                    {isFinished&&<Tag color={"#72c718"} > <Badge color={"white"}/> FINISH</Tag>}
                                </Col>
                                <Col span={11}
                                     style={{
                                         display: "flex",
                                         flexDirection: "column",
                                         alignItems: "center"
                                     }}>
                                    <Avatar src={record.away.logo}/>
                                    <div style={{textAlign: "center"}}>{record.away.name}</div>
                                    {scores &&isPlay&& < >
                                        <span style={{fontSize: 18}}> ({scores.away})</span>
                                    </>}
                                </Col></Row>;
                        }
                    },
                    actions: {
                        render: (_, record) => {
                            const val = record.commentators
                            return val && val.map?.(c => <Tag color="#5BD8A6">{c.name}</Tag>);
                        },
                    },

                }}
            />
        </div>
    );
};

export default Matches;
