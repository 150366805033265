import React, {useEffect, useRef, useState} from 'react';
import ReactHlsPlayer from "react-hls-player";
import {useSearchParams} from "react-router-dom";
import axios from "axios";
import {ReactFlvPlayer} from 'self-flv-player'
import {Button} from "antd";

const STREAM_SERVER = window._env_.STREAM_SERVER

async function fetchStreamLink(id) {
    const res = await axios.get(`https://api.thapcam.xyz/api/match/${id}/meta`);
    const data = res.data;
    return data.data?.play_urls;
}

const View = () => {
    const [params] = useSearchParams();
    const url = params.get("url");
    const referer = params.get("referer");
    const [src, setSrc] = useState("")

    async function getThapcamLink() {
        const real_id = url.split("-").at(-1);
        const links = await fetchStreamLink(real_id);

        const newSrc = links.find(l => l.name.toLowerCase() === "nhà đài")?.url;
        setSrc(newSrc)
    }

    useEffect(() => {
        if (url.includes("thapcam") && !url.endsWith("m3u8")) {
            getThapcamLink();
        } else {
            setSrc(url)
        }
    }, []);
    const playerRef = useRef();
    const isFlv = url.includes(".flv")

    isFlv && setTimeout(() => {
        // const ref = playerRef.current.myRef;
        // ref.current.requestFullscreen()

    }, 5000)
    console.log(playerRef.current)
    const handleTogglePictureInPicture = () => {
        if (document.pictureInPictureElement) {
            document.exitPictureInPicture();
        } else {
            if (isFlv) {
                playerRef.current.myRef.current.requestPictureInPicture();
            } else
                playerRef.current.requestPictureInPicture();
        }
    };
    return (
        <div style={{backgroundColor:"black",height:'100vh'}}>
            {isFlv ? <ReactFlvPlayer ref={playerRef} url={`${STREAM_SERVER}/proxy?url=${url}&referer=${referer}`}/> :
                <ReactHlsPlayer
                    playerRef={playerRef}
                    src={`${STREAM_SERVER}/stream?raw=true&url=${src}&referer=${referer}`}
                    autoPlay={false}
                    controls={true}
                    onPlay={() => {
                        // playerRef.current.requestFullscreen()
                    }}
                    hlsConfig={{maxBufferLength: 180}}
                    width="100%"
                    height="100%"
                />}
            <button onClick={handleTogglePictureInPicture}>
                PIP
            </button>
        </div>
    );
};

export default View;
