import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Button, Card, List} from "antd";

export async function sendGetRequest(link) {
    const res = await axios.get(link);
    const data = res.data;
    return data.data;
}
const STREAM_SERVER = window._env_.STREAM_SERVER
const MatchInfo = ({matchId, urls, referer,isVebo,linkId}) => {
    const [playUrls, setPlayUrls] = useState([])
    const [loading, setLoading] = useState(false);
    const fetchMatchInfos = async (id) => {
        setLoading(true)
        const links = await sendGetRequest(`https://api.vebo.xyz/api/match/${id}/meta`);
        setPlayUrls(links?.play_urls)
        setLoading(false)
    }
    const fetCKLinks = async (linkId) => {
        setLoading(true)
        const links = await sendGetRequest(`${STREAM_SERVER}/ck/links?url=${linkId}`);
        setPlayUrls(links?.play_urls)
        setLoading(false)
    }
    useEffect(() => {
        if (urls?.length) {
            setPlayUrls(urls)
        } else if(isVebo)
            fetchMatchInfos(matchId)
        else {
            fetCKLinks(linkId)
        }
    }, [matchId, urls,linkId]);
    return (
        <>
            {/*<List*/}
            {/*    dataSource={playUrls||[]}*/}
            {/*    rowKey={"id"} grid={{*/}
            {/*    gutter: 16,*/}
            {/*}}*/}
            {/*    renderItem={(item, index) => (*/}
            {/*        <List.Item>*/}
            {/*            <Button type={"link"} onClick={() => {*/}
            {/*                window.open(`https://watch.ezyproxy.com/v6/?link=${item.url}`, "_blank")*/}
            {/*            }}>{item.name}</Button>*/}
            {/*        </List.Item>*/}
            {/*    )}*/}
            {/*/>*/}
            <List loading={loading}
                  dataSource={(playUrls || []).filter(f=>f.url.endsWith("m3u8"))}
                  rowKey={"id"} grid={{
                gutter: 16,
            }}
                  renderItem={(item, index) => (
                      <List.Item style={{margin: 0}}>
                          <Button type={"link"} style={{margin: 0}} onClick={() => {
                              window.open(`${window.location.origin}/view?url=${item.url}&referer=${referer}`, "_blank")
                          }}>{item.name}</Button>
                      </List.Item>
                  )}
            />
        </>

    );
};

export default MatchInfo;
