import React, {useEffect, useState} from 'react';
import axios from "axios";
import MatchList from "./MatchList";
import Matches from "./Matches";

export const STREAM_SERVER = window._env_.STREAM_SERVER
const Cakhia = ({provider}) => {
    const [matches, setMatches] = useState([])
    const [loading, setLoading] = useState(false);
    const getAllMatches = async () => {
        setLoading(true)
        const response = await axios.get(`${STREAM_SERVER}/cakhia`);
        setMatches(response.data?.data)
        setLoading(false)
    }
    useEffect(() => {
        provider == "cakhia" && getAllMatches();
    }, [provider]);
    return (
        <div>
            <Matches matches={matches} loading={loading} referer={"https://watch.rkplayer.xyz"}/>
        </div>
    );
};

export default Cakhia;
